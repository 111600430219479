import "../styles/globals.css";
import { AbstractIntlMessages, NextIntlProvider, useTranslations } from "next-intl";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import type { AppProps } from "next/app";
import { DefaultSeo, LocalBusinessJsonLd } from "next-seo";
import { useRouter } from "next/router";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import siteUrl from "../lib/siteUrl";
import { useEffect, useState } from "react";
import { TestimonialsQuery } from "../interfaces";
import sdkReadOnly from "../lib/sdkReadOnly";

config.autoAddCss = false;

type Props = AppProps & {
  pageProps: {messages: AbstractIntlMessages}
}

function MyApp({ Component, pageProps }: Props) {
  const router = useRouter();
  const gtmParams = { id: "GTM-N767J82" };
  const [testimonials, setTestimonials] =
    useState<TestimonialsQuery["testimonials"]>();
  let averageRating = 0;

  useEffect(() => {
    const fetchTestimonials = async () => {
      const { testimonials } = await sdkReadOnly.Testimonials();

      return testimonials;
    };

    fetchTestimonials()
      .then((res) => {
        setTestimonials(res);
        testimonials?.reduce((a, b) => a + b.rating, averageRating);
      })
      .catch(console.error);
  }, [testimonials, averageRating]);

  return (
    <GTMProvider state={gtmParams}>
      <NextIntlProvider messages={pageProps.messages}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY!}
        >
          <DefaultSeo
            openGraph={{
              type: "website",
              locale: router.locale,
              url: siteUrl,
              site_name: "Dr. Birsel Uçkun Bioresonance Clinic",
              images: [
                {
                  url: siteUrl + "/logo.webp",
                  alt: "Dr. Birsel Uçkun Logo",
                },
              ],
            }}
          />
          <LocalBusinessJsonLd
            type="MedicalBusiness"
            id={siteUrl}
            name="Dr. Birsel Uçkun Bioresonance"
            description=""
            url={siteUrl}
            telephone="+447824044716"
            address={{
              streetAddress: "187 Angel Place, Fore Street",
              addressLocality: "Upper Edmonton",
              addressRegion: "London",
              postalCode: "N18 2UD",
              addressCountry: "UK",
            }}
            geo={{
              latitude: "51.6150739",
              longitude: "-0.064759",
            }}
            images={[`${siteUrl}/logo.webp`, `${siteUrl}/dr_birsel_uckun.jpg`]}
            sameAs={[siteUrl, siteUrl + "/tr"]}
            openingHours={[
              {
                opens: "09:00",
                closes: "18:00",
                dayOfWeek: [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ],
              },
            ]}
            rating={{
              ratingValue: averageRating,
              ratingCount: testimonials?.length,
            }}
            review={testimonials?.map((testimonial) => {
              return {
                author: testimonial.name,
                datePublished: testimonial.createdAt,
                reviewBody: testimonial.testimonial,
                reviewRating: {
                  ratingValue: testimonial.rating,
                },
              };
            })}
            action={{
              actionName: "potentialAction",
              actionType: "ReviewAction",
              target: siteUrl + "/review",
            }}
          />
          <Component {...pageProps} />
        </GoogleReCaptchaProvider>
      </NextIntlProvider>
    </GTMProvider>
  );
}

export default MyApp;
